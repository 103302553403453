<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>Cam kết bảo mật</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/ksppermatautama/components/img/4_1.png";

export default {
  data() {
    return {
      altText:'protocolTitle',
      importedImg1: importedImg1,
      text:`We understand that your financial data is very preciousto you. That's why our top priority is to keep our usersdata secure and safe. This policy explains whatpersonal information do we collect and store, and howwe protect your personal information by ensuring yourconfidentiality, security and privacy

1.We do not collect any personal data and usageinformation from this app.The main principle we adhere to is restricting thecollection and usage of your personal information. Ourphilosophy is the less people know about you, the moresecure you are. lf you are using, we don'thave any information that could identify you as anindividual. And we don't have any access to your in-appdata.All data is stored only in the application encryptedcontainer.
When you contact customer support you may choose tosubmit personal information regarding a problem youare experiencing with a service. This information isprocessed and stored only for better future customer support.
If you are using you will be requiredto express clear consent with these Privacy Policy andgrant us a right to collect and store your personalinformation. You can refuse to do that.

2. You are the one in control of your personalsecurityUnder no circumstances should you provide your loginand password from your iTunes account to any third-party.lf you suspect your credentials have beenobtained illegally, please immediately change youriTunes account password. We recommend that youenable two-factor authentication for your iTunesaccount.We can't be held responsible if someone elseaccesses your data through credentials obtained fromyou.

3. How do we use your personal informationWe do not use your personal information.

4.We ensure security and provide encryptionWe use all reasonable electronic and physical safetyprocedures to protect your information. All your datastores in application encrypted container so your datacan't be recovered in any other way apart from thestandard procedure of restoring it via application.

5.Updates on privacy policyWe may update this privacy policy if circumstanceschange.Changes take effect immediately after posting`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  /* margin-top: 50px; */
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  /* transform: scale(90%); */
  transform: scale(90%);
  margin: -50px auto 200px;
  height: 1000px;
  /* box-shadow:0 6px 10px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
.img1{
  margin-top: 50px;
  /* padding: 0 200px; */
}
</style>